<template>
  <thux-table
    :instance="instance"
    :instance-name="instanceName"
    :order-by-dict="orderByDict"
    :goBack="goBack"
    :list="list"
    :fieldName="fieldName"
    :fields="fields"
    :fields-column="fieldsColumn"
    :select-all="selectAll"
    :selected-list="selectedList"
    :get-local-selected-all="getLocalSelectedAll"
    :local-selected-list="localSelectedList"
    @check-all="checkAll"
    @check-none="checkNone"
    @set-selected-row="setSelectedRow"
    :show-checks="showChecks"
    :showEditButton="showEditButton"
    @change-status="changeInstanceStatus"
    @sort-changed="orderTable"
    @remove-order-by="removeOrderFromTable"
    @open-edit-form="$emit('open-edit-form', $event)"
  >
    <template slot="vat" slot-scope="row">
        {{ row.item.vat_aliquot_perc }} %
    </template>
    <template slot="price" slot-scope="row">
        {{ row.item.price_vat_excluded }} €
    </template>
    <template slot="price_vat_included" slot-scope="row">
        {{ row.item.price_vat_included }} €
    </template>
  </thux-table>

</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as PRICE_LIST_ITEM_TYPES } from './store'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxTableMixin from '../../../components/thux-table/ThuxTableMixin'
import ThuxTableChecksMixin from '../../../components/thux-table/thux-table-checks/ThuxTableChecksMixin'

export default {
  name: 'PriceListItemTable',
  mixins: [ThuxTableMixin, ThuxTableChecksMixin],
  data () {
    return {
      fields: [
        {
          key: 'checks',
          label: this.$t('Checks')
        },
        {
          key: 'status',
          label: this.$t('Status'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: true,
          value: 'status'
        },
        {
          key: 'product',
          label: this.$t('Product'),
          type: 'text',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: true,
          value: 'product_name'
        },
        {
          key: 'price',
          label: this.$t('Price'),
          tdClass: 'text-right',
          thClass: 'text-right',
          sortable: true,
          custom: true
        },
        {
          key: 'price_vat_included',
          label: this.$t('Price vat included'),
          tdClass: 'text-right',
          thClass: 'text-right',
          sortable: true,
          custom: true
        },
        {
          key: 'vat',
          label: this.$t('VAT'),
          tdClass: 'text-right',
          thClass: 'text-right',
          sortable: true,
          custom: true
        },
        {
          key: 'action',
          label: this.$t('Actions'),
          tdClass: 'text-right text-nowrap',
          thClass: 'text-right text-nowrap'
        }
      ],
      fieldsColumn: ['status', 'product', 'staff_type__name']
    }
  },
  computed: {
    ...mapGetters({
      selectAll: PRICE_LIST_ITEM_TYPES.GENERIC.price.pricelistitem.LIST.GETTERS.selectAll,
      selectedList: PRICE_LIST_ITEM_TYPES.GENERIC.price.pricelistitem.LIST.GETTERS.selectedList
    }),
    showChecks () {
      return true
    },
    showEditButton () {
      return hasPerm(['price_pricelistitem_retrieve', 'price_pricelistitem_update'])
    }
  },
  methods: {
    ...mapMutations({
      setList: PRICE_LIST_ITEM_TYPES.GENERIC.price.pricelistitem.LIST.MUTATIONS.setList,
      setSelectedList:
        PRICE_LIST_ITEM_TYPES.GENERIC.price.pricelistitem.LIST.MUTATIONS.setSelectedList,
      setSelectAll: PRICE_LIST_ITEM_TYPES.GENERIC.price.pricelistitem.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...PRICE_LIST_ITEM_TYPES.GENERIC.price.pricelistitem.LIST.ACTIONS
    })
  }
}
</script>
