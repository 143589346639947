<template>
  <panel :title="title" noButton="true" v-has-perms="rolePerm">
    <div>
      <b-form>
        <b-form-row>
          <form-thux-horizontal-autocomplete
            :validator="$v.form.product"
            :label-form="'Product' | translate"
            class-form="col-6 mb-2"
            labelColsSm="2"
            labelColsLg="2"
            :disabled="!formEditable"
            :label-search="'name'"
            :value="form.product ? { id: form.product, name: form.product_name } : null"
            :options="productListOptions"
            :placeholder="$t('Type a product')"
            :placeholder-field="form.product_name"
            @search="onSearchProduct"
            @select="selectProduct"
            @clear="clearProduct"
          />
          <form-thux-horizontal-input
            :validator="$v.form.price"
            :external-errors="errors['price']"
            :label-form="'Price' | translate"
            type="number"
            class-form="col-6 mb-2"
            labelColsSm="2"
            labelColsLg="2"
            :value="form.price"
            :disabled="!formEditable"
            @change="$set(form, 'price', $event)"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-autocomplete
            :validator="$v.form.vat"
            :label-form="'VAT' | translate"
            class-form="col-6 mb-2"
            :disabled="!formEditable"
            :customize-option="true"
            labelColsSm="2"
            labelColsLg="2"
            label-search="aliquot_perc"
            :value="form.vat ? { value: form.vat, aliquot_perc: form.vat_aliquot_perc } : null"
            :options="vatListOptions"
            unit="%"
            :placeholder="$t('VAT')"
            :placeholder-field="form.vat_aliquot_perc ? form.vat_aliquot_perc.toString() : null"
            @select="selectVat"
            @clear="clearVat"
          />
         <form-thux-horizontal-checkbox
            :validator="$v.form.price_tax_included"
            :external-errors="errors['price_tax_included']"
            :label-form="'Price vat included' | translate"
            class-form="col-6 mb-2"
            label-cols-lg="4"
            label-cols-sm="4"
            :value="form.price_tax_included"
            :disabled="!formEditable"
            @change="$set(form, 'price_tax_included', $event)"
          />
        </b-form-row>
      </b-form>
      <div class="mt-3 f-w-600 d-flex justify-content-end">
        <b-button
          class="mr-3"
          variant="default"
          @click.prevent="formEditable ? cancel() : closeForm()">
          {{ formEditable ? 'Cancel' : 'Close' | translate }}
        </b-button>
        <b-button
          variant="primary"
          v-has-perms="editRolePerm"
          @click.prevent="formEditable ? onSubmit() : makeFormEditable()"
          :disabled="$v.form.$invalid">
          {{ formEditable ? 'Save' : 'Edit' | translate }}
        </b-button>
      </div>
    </div>
  </panel>
</template>

<script>
import { vue } from '@/main'
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as PRICE_LIST_ITEM_TYPES } from './store'
import { TYPES as PRODUCT_TYPES } from '@/pages/product/product/store'
import VatMixin from '@/pages/product/product/mixins/VatMixin'

import ThuxDetailPanelMixin from '@/components/thux-detail-panel/ThuxDetailPanelMixin'

export default {
  name: 'PriceListItemEdit',
  props: {
    editParams: { default: {} }
  },
  mixins: [ThuxDetailPanelMixin, VatMixin],
  data () {
    return {
      pageName: 'Price List items',
      rolePerm: 'price_pricelistitem_retrieve',
      editRolePerm: 'price_pricelistitem_update',
      priceListId: undefined,
      itemId: undefined
    }
  },
  computed: {
    ...mapGetters({
      ...PRICE_LIST_ITEM_TYPES.GENERIC.price.pricelistitem.DETAIL.GETTERS,
      productList: PRODUCT_TYPES.GENERIC.product.product.LIST.GETTERS.list
    }),
    productListOptions () {
      const options = []
      const productList = this.productList && this.productList.results ? this.productList.results : []
      productList.forEach((product) => {
        const option = {
          id: product.id,
          name: product.name,
          status: product.status
        }
        if (option.status === 0) {
          option.$isDisabled = true
        }
        options.push(option)
      })
      return options
    }
  },
  methods: {
    ...mapMutations({
      setProductList: PRODUCT_TYPES.GENERIC.product.product.LIST.MUTATIONS.setList
    }),
    ...mapActions({
      ...PRICE_LIST_ITEM_TYPES.GENERIC.price.pricelistitem.DETAIL.ACTIONS,
      setProductFilters: PRODUCT_TYPES.GENERIC.product.product.LIST.ACTIONS.setFilters
    }),
    initFields () {
      this.$set(this, 'priceListId', this.editParams.priceListId)
      this.$set(this, 'itemId', this.editParams.itemId)
    },
    onSearchProduct (search) {
      vue.$store.commit('componentLoading', false)
      if (search.length >= 2) {
        const filters = {}
        filters.filter__name__icontains = search
        this.setProductFilters(filters).then(() => {
          vue.$store.commit('componentLoading', true)
        })
      } else {
        this.setProductList({})
        vue.$store.commit('componentLoading', true)
      }
    },
    selectProduct (item) {
      this.$set(this.form, 'product', item.id)
      this.$set(this.form, 'product_name', item.name)
    },
    clearProduct () {
      this.$set(this.form, 'product', null)
      this.$set(this.form, 'product_name', null)
    },
    getFormData () {
      const formData = Object.assign({}, this.form)
      if (!this.itemId) {
        formData.price_list = this.priceListId
      }
      if (!formData.price_tax_included) {
        formData.price_tax_included = false
      }
      return formData
    }
  },
  validations () {
    const values = {
      form: {
        price: { required },
        product: { required },
        price_tax_included: {},
        vat: {}
      }
    }
    return values
  }
}
</script>
