<template>
  <div class="panel-detail panel-fixed" v-has-perms="rolePerm">
    <div class="panel-header">
      <h1 v-if="detail.name" class="page-header">
        {{ detail.name }}
      </h1>
      <ol class="breadcrumb pull-right">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'dashboard'}">
            {{"Dashboard"}}
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link :to="{ name: routeNameList }">
            {{ title | translate }}
          </router-link>
        </li>
      </ol>
    </div>
    <div v-if="detail.name">
      <panel v-if="form" :title="$t('Price List data')" noButton="true">
        <div>
          <b-form>
            <b-form-row>
              <form-thux-horizontal-input
                :validator="$v.form.code"
                :external-errors="errors['code']"
                :label-form="'Code' | translate"
                class-form="col-lg-6 mb-3"
                labelColsSm="4"
                labelColsLg="2"
                type="text"
                :value="form.code"
                :disabled="!formEditable"
                @change="$set(form, 'code', $event)"
              />
              <form-thux-horizontal-input
                :validator="$v.form.name"
                :external-errors="errors['name']"
                :label-form="'Name' | translate"
                class-form="col-lg-6 mb-3"
                labelColsSm="4"
                labelColsLg="2"
                type="text"
                :value="form.name"
                :disabled="!formEditable"
                @change="$set(form, 'name', $event)"
              />
            </b-form-row>
            <b-form-row>
              <form-thux-horizontal-datetime
                :validator="$v.form.date_begin"
                :external-errors="errors['date_begin']"
                :label-form="'Date begin' | translate"
                class-form="col-lg-6 mb-3"
                labelColsSm="4"
                labelColsLg="2"
                :disabled="!formEditable"
                type="date"
                :value="dateBegin"
                :placeholder="$t('Enter the date')"
                @change="dateChange($event, 'date_begin')"
              />
              <form-thux-horizontal-datetime
                :validator="$v.form.date_end"
                :external-errors="errors['date_end']"
                :label-form="'Date end' | translate"
                class-form="col-lg-6 mb-3"
                labelColsSm="4"
                labelColsLg="2"
                :disabled="!formEditable"
                type="date"
                :value="dateEnd"
                :placeholder="$t('Enter the date')"
                @change="dateChange($event, 'date_end')"
              />
            </b-form-row>
          </b-form>
          <div class="mt-3 f-w-600 d-flex justify-content-end">
            <div class="mt-3 f-w-600 d-flex justify-content-end">
              <b-button
                class="mr-3"
                variant="default"
                @click.prevent="formEditable ? cancel() : closeForm()">
                {{ formEditable ? 'Cancel' : 'Close' | translate }}
              </b-button>
              <b-button
                variant="primary"
                v-has-perms="editRolePerm"
                @click.prevent="formEditable ? onSubmit() : makeFormEditable()"
                :disabled="$v.form.$invalid">
                {{ formEditable ? 'Save' : 'Edit' | translate }}
              </b-button>
            </div>
          </div>
        </div>
      </panel>
    </div>
    <price-list-item-list :priceListId="id" :is-detail="true"></price-list-item-list>
  </div>
</template>

<script>
import moment from 'moment'
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import { TYPES as PRICE_LIST_TYPES } from './store'
import ThuxDetailPanelMixin from '@/components/thux-detail-panel/ThuxDetailPanelMixin'
import PriceListItemList from '../price-list-item/PriceListItemList'

export default {
  name: 'PriceListDetail',
  mixins: [ThuxDetailPanelMixin],
  components: {
    PriceListItemList
  },
  props: ['id'],
  data () {
    return {
      routeNameList: '',
      form: undefined,
      pageName: this.$t('price list'),
      dateBegin: undefined,
      dateEnd: undefined,
      // Permissions
      rolePerm: ['price_pricelist_retrieve'],
      editRolePerm: ['price_pricelist_update']
    }
  },
  computed: {
    ...mapGetters({
      ...PRICE_LIST_TYPES.GENERIC.price.pricelist.DETAIL.GETTERS
    })
  },
  methods: {
    ...mapActions({
      ...PRICE_LIST_TYPES.GENERIC.price.pricelist.DETAIL.ACTIONS
    }),
    setForm () {
      const form = Object.assign({}, this.detail)
      this.$set(this, 'form', form)
      if (this.detail.date_begin) {
        this.$set(this, 'dateBegin', moment(new Date(this.detail.date_begin)))
      }
      if (this.detail.date_end) {
        this.$set(this, 'dateEnd', moment(new Date(this.detail.date_end)))
      }
    }
  },
  validations: {
    form: {
      code: { required },
      name: { required },
      date_begin: { required },
      date_end: { required }
    }
  }
}
</script>
