<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as PRICE_LIST_ITEM_TYPES } from './store'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxListMixin from '@/components/thux-list/ThuxListMixin'

import PriceListItemEdit from './PriceListItemEdit'
import PriceListItemTable from './PriceListItemTable'

export default {
  name: 'ProductList',
  props: ['priceListId'],
  mixins: [ThuxListMixin],
  components: {
    'component-table': PriceListItemTable,
    'component-edit': PriceListItemEdit,
    'component-detail': PriceListItemEdit
  },
  created () { },
  data () {
    return {
      title: this.$t('Price List items'),
      rolePerm: ['price_pricelistitem_list'],
      actionEnablePermission: ['price_pricelistitem_enable'],
      actionDisablePermission: ['price_pricelistitem_disable'],
      showHeader: false,
      showCommonFilters: false,
      searchFields: [
        { key: 'filter__product__name__icontains', placeholder: this.$t('Product'), type: 'text', col: 6 }
      ]
    }
  },
  computed: {
    ...mapGetters({
      ...PRICE_LIST_ITEM_TYPES.GENERIC.price.pricelistitem.LIST.GETTERS
    }),
    showList () {
      return true
    },
    canSeeActionSelect () {
      return true
    },
    canAddInstance () {
      return hasPerm('price_pricelistitem_create')
    }
  },
  methods: {
    ...mapMutations({
      setSelectedList: PRICE_LIST_ITEM_TYPES.GENERIC.price.pricelistitem.LIST.MUTATIONS.setSellectedList,
      setSelectAll: PRICE_LIST_ITEM_TYPES.GENERIC.price.pricelistitem.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...PRICE_LIST_ITEM_TYPES.GENERIC.price.pricelistitem.LIST.ACTIONS
    }),
    openEditForm (id) {
      this.$set(this.editParams, 'priceListId', this.priceListId)
      if (id) {
        this.$set(this.editParams, 'itemId', id)
        this.$set(this, 'componentId', id)
      } else {
        this.$set(this.editParams, 'itemId', undefined)
        this.$set(this, 'componentId', undefined)
      }
      this.$set(this, 'showEditForm', true)
      this.$set(this, 'showDetailForm', false)
    }
  }
}
</script>
